import React, { Component } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import FeatureCard from "./featureCard";
// import matches from "https://cricwick-fantasy.s3-us-west-2.amazonaws.com/Assets/matches.png";
// import inviteFriends from "https://cricwick-fantasy.s3-us-west-2.amazonaws.com/Assets/inviteFriends.png";
// import leagueContest from "https://cricwick-fantasy.s3-us-west-2.amazonaws.com/Assets/leagueContest.png";
// import prizes from "https://cricwick-fantasy.s3-us-west-2.amazonaws.com/Assets/prizes.png";

class FeaturesCarousel extends Component {
  state = {
    features: [
      {
        imgLink:
          "https://cricwick-fantasy.s3-us-west-2.amazonaws.com/Assets/matches.png",
        cardTitle: `Daily Matches Contests`,
        cardBody: `Consider yourself a cricket guru? Challenge the world by creating a team for each PSL-6 match.`,
      },
      // {
      //   imgLink: inviteFriends,
      //   cardTitle: `Invite Friends`,
      //   cardBody: `Increase your chance of winning by inviting your friends to daily HBL PSL matches and getting 10% extra points on leaderboard per match.`,
      // },
      {
        imgLink:
          "https://cricwick-fantasy.s3-us-west-2.amazonaws.com/Assets/leagueContest.png",
        cardTitle: `League Contest`,
        cardBody: `The accumulation of your daily match points will be added in the league contest and the table-topper will win the mega prize.`,
      },
      {
        imgLink:
          "https://cricwick-fantasy.s3-us-west-2.amazonaws.com/Assets/prizes.png",
        cardTitle: `Exciting Prizes`,
        cardBody: `Your chance to win exciting cash and other prizes on daily basis as well as a mega prize at the end of the league.`,
      },
    ],
  };
  render() {
    return (
      <div className={`mt-2`}>
        <AliceCarousel
          mouseTracking
          onSlideChanged={this.props.onClick}
          disableButtonsControls
          animationDuration={400}
          // autoPlayInterval={2000}
          // autoPlay
          // infinite
          activeIndex={this.props.slideNumber}
          responsive={{
            0: {
              items: 1,
            },
            991: {
              items: 4,
            },
          }}
          items={this.state.features.map((feature) => {
            return (
              <div
                key={feature.cardTitle}
                className={`d-flex justify-content-center`}
              >
                <FeatureCard feature={feature} />
              </div>
            );
          })}
        />
      </div>
    );
  }
}

export default FeaturesCarousel;
