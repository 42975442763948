import React, { Component } from "react";
import ButtonComponent from "./common/button";
import homeStyles from "../css/home.module.css";

class Home extends Component {
  state = {};

  render() {
    return (
      <div className={`${homeStyles.home}`}>
        <div
          className={`${homeStyles.homeButton} w-100 d-flex justify-content-center justify-content-lg-end`}
        >
          <ButtonComponent
            label={`Create your team now`}
            style={{
              backgroundColor: `#BA1617`,
            }}
            classes={`${homeStyles.button}`}
            onClick={() =>
              window.open(
                `http://psl2021.adsksa.mobi/fantasy/2${
                  window.location.search.length
                    ? window.location.search
                    : "?utm_medium=mystars11_page"
                }&utm_source=167`,
                `_self`
              )
            }
          />
        </div>
      </div>
    );
  }
}

export default Home;
