import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PointsCarousel from "./pointsCarousel";
import commonStyles from "../../css/common.module.css";

class Root extends Component {
  state = {
    slideNumber: 0,
  };
  render() {
    return (
      <div className={`${commonStyles.root}`}>
        <div>
          <Typography
            classes={{
              root: `d-flex justify-content-center font-weight-bold ${commonStyles.heading}`,
            }}
            variant="h6"
            gutterBottom
          >
            <Box
              display="inline"
              classes={{ root: `${commonStyles.redHeading}` }}
              style={{
                color: "#b91414",
              }}
            >
              POINTS
            </Box>
            TABLE
          </Typography>
        </div>
        <div className={`d-flex justify-content-center`}>
          <Typography
            classes={{
              root: `d-flex justify-content-center text-center w-75 mt-3 ${commonStyles.subHeading}`,
            }}
            variant="caption"
            gutterBottom
          >
            Your each selected player would be earning points based on the
            following criteria, dependent on his actual performances in every
            game.
          </Typography>
        </div>
        <div className={`${commonStyles.carouselDiv}`}>
          <PointsCarousel
            onClick={(e) =>
              this.setState({
                slideNumber: e.item,
              })
            }
            slideNumber={this.state.slideNumber}
          />
        </div>
      </div>
    );
  }
}

export default Root;
