import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Instruction from "./instruction";
import InstructionsCarousel from "./instructionsCarousel";
import InstructionsDesktop from "./instructionsDesktop";
import commonStyles from "../../css/common.module.css";
import instructionStyles from "../../css/howToPlay/instruction.module.css";

class Root extends Component {
  state = {
    slideNumber: 0,
    instructions: [
      {
        title: `Select matches`,
        body: `Select any number of schedule matches from the list you wish to play.`,
      },
      {
        title: `Select players`,
        body: `Select your favorite players from both sides.`,
      },
      {
        title: `Select cap & vice cap`,
        body: `Each team you create must have its captain and vice captain selected.`,
      },
      {
        title: `Follow leaderboard`,
        body: `Leaderboard is the place to keep a check on your points based on the actual performance of your players.`,
      },
    ],
  };

  handleSlideChange = (slideNumber) => this.setState({ slideNumber });

  render() {
    return (
      <div className={`${commonStyles.root}`}>
        <div>
          <Typography
            classes={{
              root: `d-flex justify-content-center font-weight-bold ${commonStyles.heading}`,
            }}
            variant="h6"
            gutterBottom
          >
            <Box
              display="inline"
              classes={{ root: `${commonStyles.redHeading}` }}
              style={{
                color: "#b91414",
              }}
            >
              HOW
            </Box>
            TO PLAY
          </Typography>
        </div>
        <div className={`d-flex justify-content-center`}>
          <Typography
            classes={{
              root: `d-flex justify-content-center text-center w-75 mt-3 ${commonStyles.subHeading}`,
            }}
            variant="caption"
            gutterBottom
          >
            Follow the following steps to create your fantasy Team, using your
            free credit quota.
          </Typography>
        </div>
        <div className={`d-flex flex-column flex-lg-row ml-2`}>
          <div className={`${instructionStyles.instruction}`}>
            <Instruction
              slideNumber={this.state.slideNumber}
              instructions={this.state.instructions[this.state.slideNumber]}
            />
          </div>
          <div className={`d-lg-none`}>
            <InstructionsCarousel
              onClick={(e) =>
                this.setState({
                  slideNumber: e.item,
                })
              }
              slideNumber={this.state.slideNumber}
            />
          </div>
          <div className={`d-none d-lg-block`}>
            <InstructionsDesktop
              slideNumber={this.state.slideNumber}
              onClick={this.handleSlideChange}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Root;
