import React, { Component } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
// import step1 from "https://cricwick-fantasy.s3-us-west-2.amazonaws.com/Assets/step1.png";
// import step2 from "https://cricwick-fantasy.s3-us-west-2.amazonaws.com/Assets/step2.png";
// import step3 from "https://cricwick-fantasy.s3-us-west-2.amazonaws.com/Assets/step3.png";
// import step4 from "https://cricwick-fantasy.s3-us-west-2.amazonaws.com/Assets/step4.png";

class InstructionsCarousel extends Component {
  state = {
    instructions: [
      {
        imgLink: `https://cricwick-fantasy.s3-us-west-2.amazonaws.com/Assets/step1.png`,
      },
      {
        imgLink: `https://cricwick-fantasy.s3-us-west-2.amazonaws.com/Assets/step2.png`,
      },
      {
        imgLink: `https://cricwick-fantasy.s3-us-west-2.amazonaws.com/Assets/step3.png`,
      },
      {
        imgLink: `https://cricwick-fantasy.s3-us-west-2.amazonaws.com/Assets/step4.png`,
      },
    ],
  };
  render() {
    return (
      <div className={`mt-2`}>
        <AliceCarousel
          mouseTracking
          onSlideChanged={this.props.onClick}
          disableButtonsControls
          activeIndex={this.props.slideNumber}
          responsive={{
            0: {
              items: 1,
            },
          }}
          items={this.state.instructions.map((instruction, index) => {
            return (
              <div
                key={`Step${index}`}
                className={`d-flex justify-content-center pb-1`}
              >
                <div
                  style={{
                    maxWidth: `300px`,
                    width: `60%`,
                    height: `auto`,
                    boxShadow: `2px 2px 5px 1px #888888`,
                  }}
                >
                  <img
                    src={instruction.imgLink}
                    alt={`Step ${index}`}
                    style={{
                      width: `100%`,
                      height: `100%`,
                    }}
                  />
                </div>
              </div>
            );
          })}
        />
      </div>
    );
  }
}

export default InstructionsCarousel;
