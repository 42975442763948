import React, { Component } from "react";
import commonStyles from "../css/common.module.css";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

class FAQ extends Component {
  state = {};
  render() {
    return (
      <div className={`${commonStyles.root}`}>
        <div>
          <Typography
            classes={{
              root: `d-flex justify-content-center font-weight-bold ${commonStyles.heading}`,
            }}
            variant="h6"
            gutterBottom
          >
            <Box
              display="inline"
              classes={{ root: `${commonStyles.redHeading}` }}
              style={{
                color: "#b91414",
              }}
            >
              FAQ'S
            </Box>
          </Typography>
        </div>
        <div className={`d-flex justify-content-center w-100 pb-5`}>
          <Typography
            classes={{
              root: `text-center mt-1 w-75 ${commonStyles.subHeading}`,
            }}
            variant="caption"
            gutterBottom
          >
            Have Questions? Check our{" "}
            <Box
              display="inline"
              classes={{
                root: `font-weight-bold text-center ${commonStyles.subHeading}`,
              }}
              style={{
                color: "#b91414",
                cursor: `pointer`,
              }}
              onClick={() =>
                window.open(
                  `https://cricwick-fantasy.s3-us-west-2.amazonaws.com/FAQs/MyStars11FAQ.pdf`
                )
              }
            >
              Frequently asked questions.
            </Box>
          </Typography>
        </div>
      </div>
    );
  }
}

export default FAQ;
