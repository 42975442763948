import React, { Component } from "react";
import Home from "./home";
import Prizes from "./prizes";
// import PlayMyStars from "./playMyStars";
import Faq from "./faq";
import HowToPlay from "./howToPlay/root";
import Features from "./features/root";
import Points from "./points/root";
import Footer from "./footer";
import commonStyles from "../css/common.module.css";

class Root extends Component {
  state = {};
  render() {
    return (
      <div>
        <div>
          <Home />
        </div>
        <div>
          <Prizes />
        </div>
        <div className={`${commonStyles.darkBg}`}>
          <Features />
        </div>
        <div>
          <HowToPlay />
        </div>
        <div>
          <Points />
        </div>
        {/* <div>
          <PlayMyStars />
        </div> */}
        <div>
          <Faq />
        </div>
        <div className={`${commonStyles.darkBg}`}>
          <Footer />
        </div>
      </div>
    );
  }
}

export default Root;
