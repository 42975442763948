import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import commonStyles from "../../css/common.module.css";

class ButtonComponent extends Component {
  state = {};
  render() {
    let {
      label = "Button",
      variant = "contained",
      color = "primary",
      onClick = null,
      href = null,
      disabled = null,
      EndIcon = null,
      endIconClasses = null,
      StartIcon = null,
      style = null,
      classes = null,
      labelClasses = null,
      roundedCorners = false,
      disableElevation = false,
      textTransformNone = false,
    } = this.props;
    return (
      <Button
        classes={{
          root: `${classes} ${
            roundedCorners ? commonStyles.roundedCorners : null
          } ${commonStyles.removeStroke} ${
            textTransformNone ? commonStyles.textTransformNone : ""
          }`,
          label: `${labelClasses}`,
          endIcon: `${endIconClasses}`,
        }}
        variant={variant}
        color={color}
        onClick={onClick}
        href={href}
        disabled={disabled}
        startIcon={StartIcon ? <StartIcon /> : StartIcon}
        endIcon={EndIcon ? <EndIcon /> : EndIcon}
        style={{
          ...style,
          opacity: disabled ? `0.3` : `1`,
        }}
        disableElevation={disableElevation}
      >
        {label}
      </Button>
    );
  }
}

export default ButtonComponent;
