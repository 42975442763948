import React, { Component } from "react";
// import logo from "https://cricwick-fantasy.s3-us-west-2.amazonaws.com/Assets/logo.png";
import Typography from "@material-ui/core/Typography";
import ButtonComponent from "../components/common/button";
// import fb from "https://cricwick-fantasy.s3-us-west-2.amazonaws.com/Assets/fb.png";
// import twitter from "https://cricwick-fantasy.s3-us-west-2.amazonaws.com/Assets/twitter.png";
// import appStore from "https://cricwick-fantasy.s3-us-west-2.amazonaws.com/Assets/appStore.png";
// import googlePlay from "https://cricwick-fantasy.s3-us-west-2.amazonaws.com/Assets/googlePlay.png";
import footerStyles from "../css/footer.module.css";

class Footer extends Component {
  state = {};
  render() {
    return (
      <div
        className={`${footerStyles.root} d-flex flex-column flex-lg-row justify-content-lg-around`}
      >
        <div
          className={`d-flex justify-content-center ${footerStyles.logoDiv} my-auto`}
        >
          <img
            src={
              "https://cricwick-fantasy.s3-us-west-2.amazonaws.com/Assets/logo.png"
            }
            alt={`my cricket 11 logo`}
            className={`${footerStyles.logo}`}
          />
        </div>
        <div className={`${footerStyles.copyRightsDiv} my-lg-auto`}>
          <Typography
            classes={{
              root: `d-flex justify-content-center ${footerStyles.copyRights}`,
            }}
            style={{
              color: `white`,
            }}
            variant="caption"
            gutterBottom
          >
            &copy;cricwick All rights reserved
          </Typography>
        </div>
        <div>
          <div
            className={`d-flex flex-row justify-content-center ${footerStyles.socialMediaButtonsDiv}`}
          >
            <div>
              <ButtonComponent
                label={
                  <img
                    src={
                      "https://cricwick-fantasy.s3-us-west-2.amazonaws.com/Assets/fb.png"
                    }
                    alt={`sjndjs`}
                    className={`${footerStyles.socialMediaButtonImg}`}
                  />
                }
                variant={`text`}
                style={{
                  padding: `0`,
                }}
                onClick={() =>
                  window.open(
                    `https://www.facebook.com/My-Stars-11-157782019411332`
                  )
                }
              />
              <ButtonComponent
                label={
                  <img
                    src={
                      "https://cricwick-fantasy.s3-us-west-2.amazonaws.com/Assets/twitter.png"
                    }
                    alt={`sjndjs`}
                    className={`${footerStyles.socialMediaButtonImg}`}
                  />
                }
                classes={`${footerStyles.socialMediaButtonLeftDiv}`}
                variant={`text`}
                style={{
                  padding: `0`,
                }}
                onClick={() => window.open(`https://twitter.com/MyStars112`)}
              />
            </div>
          </div>
          <div
            className={`d-flex flex-row justify-content-center ${footerStyles.socialMediaButtonsDiv}`}
          >
            <div>
              <ButtonComponent
                label={
                  <img
                    src={
                      "https://cricwick-fantasy.s3-us-west-2.amazonaws.com/Assets/googlePlay.png"
                    }
                    alt={`cricwick android app link`}
                    className={`${footerStyles.downloadButtons}`}
                  />
                }
                variant={`text`}
                style={{
                  padding: `0`,
                }}
                onClick={() =>
                  window.open(
                    `https://play.google.com/store/apps/details?id=com.Khaleef.CricWick.TelenorZong`
                  )
                }
              />
              <ButtonComponent
                label={
                  <img
                    src={
                      "https://cricwick-fantasy.s3-us-west-2.amazonaws.com/Assets/appStore.png"
                    }
                    alt={`cricwick ios app link`}
                    className={`${footerStyles.downloadButtons}`}
                  />
                }
                variant={`text`}
                style={{
                  padding: `0`,
                  marginLeft: `10px`,
                }}
                onClick={() =>
                  window.open(
                    "https://apps.apple.com/pk/app/cricwick-pakistan/id1461475044"
                  )
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
