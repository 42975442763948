import React, { Component } from "react";
// import step1 from "../../assets/step1.png";
// import step2 from "../../assets/step2.png";
// import step3 from "../../assets/step3.png";
// import step4 from "../../assets/step4.png";
import instructionsStyle from "../../css/howToPlay/instructions.module.css";

class InstructionsCarousel extends Component {
  state = {
    instructions: [
      {
        imgLink: `https://cricwick-fantasy.s3-us-west-2.amazonaws.com/Assets/step1.png`,
      },
      {
        imgLink: `https://cricwick-fantasy.s3-us-west-2.amazonaws.com/Assets/step2.png`,
      },
      {
        imgLink: `https://cricwick-fantasy.s3-us-west-2.amazonaws.com/Assets/step3.png`,
      },
      {
        imgLink: `https://cricwick-fantasy.s3-us-west-2.amazonaws.com/Assets/step4.png`,
      },
    ],
  };
  render() {
    return (
      <div className={`mt-2 d-flex justify-content-between`}>
        {this.state.instructions.map((instruction, index) => {
          return (
            <div
              key={instruction.alt}
              className={`d-flex justify-content-center my-auto`}
            >
              <div
                className={`${
                  this.props.slideNumber === index
                    ? instructionsStyle.imgDivActive
                    : instructionsStyle.imgDiv
                }`}
                onClick={() => this.props.onClick(index)}
              >
                <img
                  src={instruction.imgLink}
                  alt={instruction.alt}
                  className={`${instructionsStyle.img}`}
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default InstructionsCarousel;
