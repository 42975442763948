import React, { Component } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import PointsCard from "./pointsCard";
// import bat from "https://cricwick-fantasy.s3-us-west-2.amazonaws.com/Assets/bat.png";
// import bowl from "https://cricwick-fantasy.s3-us-west-2.amazonaws.com/Assets/bowl.png";
// import field from "https://cricwick-fantasy.s3-us-west-2.amazonaws.com/Assets/field.png";
// import bonus from "https://cricwick-fantasy.s3-us-west-2.amazonaws.com/Assets/bonus.png";

class PointsCarousel extends Component {
  state = {};

  returnUpperPartPoints = (data) => {
    let points = [];
    for (let i = 0; i < data.length; i++)
      points.push({
        heading: `${data[i].minRate} to ${data[i].maxRate}`,
        value: `${data[i].points}`,
        negativeValue: data[i].points < 0 ? true : false,
      });
    return points;
  };

  render() {
    const {
      criteria = {
        title: "Fantasy General Criteria",
        batScore: 10,
        batScoreThreshold: 25,
        wicket: 10,
        maidenOver: 20,
        duck: -5,
        six: 4,
        four: 2,
        winningTeam: 5,
        motm: 25,
        dotball: 1,
        catch: 10,
        stump: 15,
        runout: 10,
        strikeRates: [
          {
            minRate: 0.1,
            maxRate: 75,
            points: -15,
          },
          {
            minRate: 150.1,
            maxRate: 1000,
            points: 15,
          },
        ],
        economyRates: [
          {
            minRate: 0.1,
            maxRate: 5,
            points: 15,
          },
          {
            minRate: 5.01,
            maxRate: 8,
            points: 10,
          },
          {
            minRate: 8.01,
            maxRate: 10,
            points: 5,
          },
          {
            minRate: 10.01,
            maxRate: 12,
            points: -10,
          },
          {
            minRate: 12.01,
            maxRate: 1000,
            points: -15,
          },
        ],
        capMultiplier: 2,
        vCapMultiplier: 1.5,
      },
    } = this.props;

    return (
      <div className={`mt-2`}>
        <AliceCarousel
          mouseTracking
          onSlideChanged={this.props.onClick}
          disableButtonsControls
          animationDuration={400}
          // autoPlayInterval={2000}
          // autoPlay
          // infinite
          activeIndex={this.props.slideNumber}
          responsive={{
            0: {
              items: 1,
            },
            991: {
              items: 4,
            },
          }}
          items={[
            <div className={`d-flex justify-content-center`}>
              <PointsCard
                heading={"Batting"}
                imgLink={`https://cricwick-fantasy.s3-us-west-2.amazonaws.com/Assets/bat.png`}
                upperPart={{
                  heading: "Strike",
                  points: this.returnUpperPartPoints(criteria.strikeRates),
                }}
                lowerPart={[
                  {
                    heading: "Each four",
                    value: `${criteria.four}`,
                    negativeValue: criteria.four < 0 ? true : false,
                  },
                  {
                    heading: "Each six",
                    value: `${criteria.six}`,
                    negativeValue: criteria.six < 0 ? true : false,
                  },
                  {
                    heading: "Out for a Duck (Except Bowlers)",
                    value: `${criteria.duck}`,
                    negativeValue: criteria.duck < 0 ? true : false,
                  },
                  {
                    heading: "On every 25 runs",
                    value: `${criteria.batScore}`,
                    negativeValue: criteria.batScore < 0 ? true : false,
                  },
                ]}
              />
            </div>,
            <div className={`d-flex justify-content-center`}>
              <PointsCard
                heading={"Bowling"}
                imgLink={
                  "https://cricwick-fantasy.s3-us-west-2.amazonaws.com/Assets/bowl.png"
                }
                upperPart={{
                  heading: "Economy",
                  points: this.returnUpperPartPoints(criteria.economyRates),
                }}
                lowerPart={[
                  {
                    heading: "Each wicket",
                    value: `${criteria.wicket}`,
                    negativeValue: criteria.wicket < 0 ? true : false,
                  },
                  {
                    heading: "Per dot ball bowled",
                    value: `${criteria.dotball}`,
                    negativeValue: criteria.dotball < 0 ? true : false,
                  },
                  {
                    heading: "Maiden Over Points",
                    value: `${criteria.maidenOver}`,
                    negativeValue: criteria.maidenOver < 0 ? true : false,
                  },
                ]}
              />
            </div>,
            <div className={`d-flex justify-content-center`}>
              <PointsCard
                heading={"Fielding"}
                imgLink={
                  "https://cricwick-fantasy.s3-us-west-2.amazonaws.com/Assets/field.png"
                }
                lowerPart={[
                  {
                    heading: "Catch resulted Wicket",
                    value: `${criteria.catch}`,
                    negativeValue: criteria.catch < 0 ? true : false,
                  },
                  {
                    heading: "Stumps resulted Wicket",
                    value: `${criteria.stump}`,
                    negativeValue: criteria.stump < 0 ? true : false,
                  },
                  {
                    heading: "Run Out (only thrower)",
                    value: `${criteria.runout}`,
                    negativeValue: criteria.runout < 0 ? true : false,
                  },
                ]}
              />
            </div>,
            <div className={`d-flex justify-content-center`}>
              <PointsCard
                heading={"Bonus"}
                imgLink={
                  "https://cricwick-fantasy.s3-us-west-2.amazonaws.com/Assets/bonus.png"
                }
                lowerPart={[
                  {
                    heading: "Captain gets",
                    value: `${criteria.capMultiplier}X`,
                  },
                  {
                    heading: "Vice Captain gets",
                    value: `${criteria.vCapMultiplier}X`,
                  },
                  {
                    heading: "Player of the match",
                    value: `${criteria.motm}`,
                    negativeValue: criteria.motm < 0 ? true : false,
                  },
                  {
                    heading: "Winning team (for each player)",
                    value: `${criteria.winningTeam}`,
                    negativeValue: criteria.winningTeam < 0 ? true : false,
                  },
                ]}
              />
            </div>,
          ]}
        />
      </div>
    );
  }
}

export default PointsCarousel;
