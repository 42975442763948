import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import commonStyles from "../../css/common.module.css";
import instructionStyles from "../../css/howToPlay/instruction.module.css";
// import wicket from "https://cricwick-fantasy.s3-us-west-2.amazonaws.com/Assets/wicket.png";
// import bat from "https://cricwick-fantasy.s3-us-west-2.amazonaws.com/Assets/bat1.png";
// import ball from "https://cricwick-fantasy.s3-us-west-2.amazonaws.com/Assets/ball.png";
// import allRounder from "https://cricwick-fantasy.s3-us-west-2.amazonaws.com/Assets/allRounder.png";
// import captain from "https://cricwick-fantasy.s3-us-west-2.amazonaws.com/Assets/captainX.png";
// import viceCaptain from "https://cricwick-fantasy.s3-us-west-2.amazonaws.com/Assets/viceCaptainX.png";

class Instruction extends Component {
  state = {};

  returnInfoComponent = (
    data = {
      image:
        "https://cricwick-fantasy.s3-us-west-2.amazonaws.com/Assets/wicket.png",
      alt: "wicket",
      range: "04-012",
      role: "wicket keeper",
    }
  ) => {
    return (
      <div className={`pt-2 pb-2`}>
        <div className={`d-flex justify-content-center`}>
          <img src={data.image} alt={data.alt} width={24} height={24} />
        </div>
        <div className={`d-flex justify-content-center mt-2`}>
          <Typography
            variant="subtitle2"
            classes={{
              root: `font-weight-bold`,
            }}
          >
            {data.range}
          </Typography>
        </div>
        <div className={`d-flex justify-content-center`}>
          <Typography variant="caption" align="center" gutterBottom>
            {data.role}
          </Typography>
        </div>
      </div>
    );
  };

  returnDividerComponent = () => {
    return (
      <Divider
        orientation={"vertical"}
        flexItem
        style={{
          backgroundColor: "white",
          width: "2px",
        }}
      />
    );
  };

  returnHowToPlay = () => {
    return (
      <div
        className={`mt-3 d-flex justify-content-around ${commonStyles.blueGradientBg}`}
      >
        {this.returnInfoComponent({
          image:
            "https://cricwick-fantasy.s3-us-west-2.amazonaws.com/Assets/wicket.png",
          alt: "wicket",
          range: `${`01`}`,
          role: "wicket keeper",
        })}
        {this.returnDividerComponent()}
        {this.returnInfoComponent({
          image:
            "https://cricwick-fantasy.s3-us-west-2.amazonaws.com/Assets/bat1.png",
          alt: "bat",
          range: `4-5`,
          role: "batsmen",
        })}
        {this.returnDividerComponent()}
        {this.returnInfoComponent({
          image:
            "https://cricwick-fantasy.s3-us-west-2.amazonaws.com/Assets/ball.png",
          alt: "ball",
          range: `2-5`,
          role: "Bowlers",
        })}
        {this.returnDividerComponent()}
        {this.returnInfoComponent({
          image:
            "https://cricwick-fantasy.s3-us-west-2.amazonaws.com/Assets/allRounder.png",
          alt: "two bats crossed, and a ball",
          range: `1-4`,
          role: "All rounders",
        })}
      </div>
    );
  };

  returnCapAndViceCapComponent = (
    data = {
      image:
        "https://cricwick-fantasy.s3-us-west-2.amazonaws.com/Assets/captainX.png",
      points: "2",
      type: 0,
    }
  ) => {
    return (
      <div className={``}>
        <div className={`d-flex justify-content-center`}>
          <img
            src={data.image}
            alt={`${!data.type ? "captain" : "Vice Captain"}`}
            width={68}
            height={68}
          />
        </div>
        <div className={`d-flex justify-content-center`}>
          <Typography
            variant="body2"
            align="center"
            style={{
              fontWeight: "500",
            }}
            gutterBottom
          >
            Gets{" "}
            <Box fontWeight={"bold"} display={"inline"} fontSize={"15px"}>
              {data.points}X
            </Box>{" "}
            Points
          </Typography>
        </div>
      </div>
    );
  };

  returnCapsAndViceCaps = () => {
    return (
      <div
        className={`mt-3 d-flex justify-content-around ${commonStyles.blueGradientBg}`}
      >
        {this.returnCapAndViceCapComponent({
          image:
            "https://cricwick-fantasy.s3-us-west-2.amazonaws.com/Assets/captainX.png",
          type: 0,
          points: 2,
        })}
        {this.returnDividerComponent()}
        {this.returnCapAndViceCapComponent({
          image:
            "https://cricwick-fantasy.s3-us-west-2.amazonaws.com/Assets/viceCaptainX.png",
          type: 1,
          points: 1.5,
        })}
      </div>
    );
  };

  render() {
    return (
      <div>
        <div className={`d-flex justify-content-start`}>
          <div className={`d-flex `}>
            <div>
              <Typography
                classes={{
                  root: `d-flex justify-content-center font-weight-bold mt-3 ${instructionStyles.slideNumber}`,
                }}
                variant="h2"
                style={{
                  color: `#FFC300`,
                }}
              >
                {`0${this.props.slideNumber + 1}`}
              </Typography>
            </div>
            <div className={`ml-2 w-75`}>
              <div>
                <Typography
                  classes={{
                    root: `d-flex justify-content-start font-weight-bold mt-3 text-uppercase ${commonStyles.heading}`,
                  }}
                  variant="h6"
                  gutterBottom
                >
                  {this.props.instructions.title}
                </Typography>
              </div>
              <div
                className={`d-flex justify-content-start`}
                style={{
                  minHeight: `61px`,
                  height: `100%`,
                }}
              >
                <Typography
                  classes={{
                    root: `d-flex justify-content-start ${commonStyles.subHeading}`,
                  }}
                  variant="caption"
                  gutterBottom
                >
                  {this.props.instructions.body}
                </Typography>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            maxWidth: `700px`,
            width: `100%`,
          }}
        >
          {this.props.slideNumber === 1 ? (
            this.returnHowToPlay()
          ) : this.props.slideNumber === 2 ? (
            this.returnCapsAndViceCaps()
          ) : (
            <div
              className={`mt-3 d-flex justify-content-around ${commonStyles.blueGradientBg}`}
              style={{
                height: `93px`,
              }}
            />
          )}
        </div>
      </div>
    );
  }
}

export default Instruction;
