import React, { Component } from "react";
import prizeStyles from "../css/prizes.module.css";

class Pizes extends Component {
  state = {};
  render() {
    return <div className={`${prizeStyles.prizes}`} />;
  }
}

export default Pizes;
