import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import commonStyles from "../../css/common.module.css";
import pointsCardStyles from "../../css/points/pointsCard.module.css";

class PointsCard extends Component {
  state = {};

  returnPointsRow = (data, key) => {
    return (
      <div
        key={key}
        className={`d-flex justify-content-between mb-1 pr-3 pl-3 w-100`}
      >
        <Typography
          variant="caption"
          gutterBottom
          style={{
            color: `white`,
          }}
          classes={{ root: `${pointsCardStyles.pointsHeading}` }}
        >
          {data.heading}
        </Typography>
        <Typography
          variant="caption"
          classes={{
            root: `font-weight-bold`,
          }}
          style={{
            color: `#FFC300`,
          }}
          classes={{ root: `${pointsCardStyles.pointsHeading}` }}
          gutterBottom
        >
          {data.value} Pts
        </Typography>
      </div>
    );
  };

  render() {
    let {
      heading = "Daily Matches Contests",
      cardBody = `Create Your own marcgh`,
      imgLink,
      upperPart = null,
      lowerPart = [],
    } = this.props;
    return (
      <div
        className={`d-flex flex-column ${commonStyles.darkBg} ${pointsCardStyles.cardRootDiv}`}
      >
        <div className={`d-flex justify-content-center mt-4`}>
          <img
            src={imgLink}
            alt={heading}
            style={{
              width: `20vh`,
              height: `20vh`,
            }}
          />
        </div>
        <div className={`mt-4 pl-3`}>
          <div>
            <Typography
              classes={{
                root: `font-weight-bold text-uppercase`,
              }}
              variant="h5"
              gutterBottom
              style={{
                color: `white`,
              }}
            >
              {heading} Points
            </Typography>
          </div>
        </div>
        {upperPart ? (
          <div className={`w-100`}>
            <div className={`d-flex justify-content-between mt-2 pr-3 pl-3`}>
              <Typography
                variant="caption"
                classes={{
                  root: `font-weight-bold ${pointsCardStyles.upperPartHeading}`,
                }}
                style={{
                  color: `white`,
                }}
                gutterBottom
              >
                {upperPart.heading} Rate
              </Typography>
              <Typography variant="caption"></Typography>
            </div>
            {upperPart.points.map((item, index) => {
              return this.returnPointsRow(item, index);
            })}
            <Divider
              style={{
                backgroundColor: "#eef8fe",
              }}
              variant="fullWidth"
            />
          </div>
        ) : null}
        <div className={`mt-2 pb-3`}>
          {lowerPart.map((item, index) => {
            return this.returnPointsRow(item, index);
          })}
        </div>
        {/* <div className={`d-flex flex-column w-100`}>
          
        </div> */}
      </div>
    );
  }
}

export default PointsCard;
