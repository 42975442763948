import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import featureCardStyles from "../../css/features/featureCard.module.css";

class FeatureCard extends Component {
  state = {};
  render() {
    let {
      cardTitle = "Daily Matches Contests",
      cardBody = `Create Your own marcgh`,
      imgLink,
    } = this.props.feature;
    return (
      <div className={`d-flex flex-column ${featureCardStyles.cardRootDiv}`}>
        <div className={`d-flex justify-content-center mt-3`}>
          <img
            src={imgLink}
            alt={cardTitle}
            style={{
              width: `20vh`,
              height: `20vh`,
            }}
          />
        </div>
        <div className={`mt-3`}>
          <div>
            <Typography
              classes={{
                root: `d-flex justify-content-center font-weight-bold mt-3 text-uppercase text-center`,
              }}
              variant="h5"
              gutterBottom
              style={{
                color: `#FFC300`,
              }}
            >
              {cardTitle}
            </Typography>
          </div>
          <div className={`d-flex justify-content-center`}>
            <Typography
              classes={{
                root: `d-flex justify-content-center text-center w-75`,
              }}
              variant="subtitle1"
              gutterBottom
              style={{
                color: `white`,
              }}
            >
              {cardBody}
            </Typography>
          </div>
        </div>
      </div>
    );
  }
}

export default FeatureCard;
